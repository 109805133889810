import { useTranslation } from "@tecma/i18n";
import type { FieldErrors } from "react-hook-form";

import type { AccountFields } from "components/AccountManagerDrawer/AccountManagerDrawer";

const useFieldValidator = () => {
  const { t } = useTranslation();
  const getFieldMaxLength = (field: keyof AccountFields) => {
    if (field === "email") {
      return 40;
    }
    return 50;
  };
  const getFieldError = (
    field: keyof AccountFields,
    formErrors: FieldErrors,
  ) => {
    if (formErrors && formErrors[field]?.type) {
      return t(`iTd.field.error.${formErrors[field]?.type}`, {
        maxLength: getFieldMaxLength(field),
        field: t(`iTd.accountManager.field.${field}.title`).toLocaleLowerCase(),
      });
    }
    return null;
  };
  return {
    getFieldError,
    getFieldMaxLength,
  };
};
export default useFieldValidator;
