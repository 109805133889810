import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useTranslation } from "@tecma/i18n";
import { Button, Drawer, Input, PhoneInput, Select, Spinner } from "@tecma/ds";
import {
  PhoneCountry,
  getCountryAndPrefix,
  isValidNumber,
} from "@tecma/ds/lib/components/PhoneInput/utils/functions";
import { Locales } from "@tecma/ds/lib/components/PhoneInput/utils/locales";
import type { OptionSelect } from "@tecma/ds/lib/components/Select/Select";
import { Controller } from "react-hook-form";

import { ROLES } from "constants/role";
import { NAME_REGEX, EMAIL_REGEX } from "constants/regex";
import { formatDate } from "utils/date";
import { useStore } from "store/storeUtils";
import useBackOfficeUserForm from "customHooks/useBackOfficeUserForm";
import type { BackOfficeUser } from "pages/AccountManager/AccountManager";

import "./account-manager-drawer.scss";
import useFieldValidator from "customHooks/useFieldValidator";

export interface AccountFieldErrors {
  name?: string;
  surname?: string;
  email?: string;
  phone?: string;
  language?: string;
  role?: string;
  assets?: string;
}
export interface AccountFields {
  name?: string;
  surname?: string;
  email?: string;
  phone?: string;
  language?: OptionSelect;
  role?: OptionSelect;
  assets?: OptionSelect[];
}
interface AccountManagerDrawerRequiredProps {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
}
interface AccountManagerDrawerOptionalProps {
  accountFields?: AccountFields;
  onClose?: () => void;
  onSuccess?: () => void;
  user?: BackOfficeUser;
}
interface AccountManagerDrawerProps
  extends AccountManagerDrawerRequiredProps,
    AccountManagerDrawerOptionalProps {}

const roleOptions: OptionSelect[] = [
  {
    label: ROLES.ACCOUNT_MANAGER.label,
    value: ROLES.ACCOUNT_MANAGER.value,
  },
  {
    label: ROLES.VENDOR.label,
    value: ROLES.VENDOR.value,
  },
  {
    label: ROLES.VENDOR_MANAGER.label,
    value: ROLES.VENDOR_MANAGER.value,
  },
  {
    label: ROLES.PROPRIETA.label,
    value: ROLES.PROPRIETA.value,
  },
  {
    label: ROLES.FRONT_OFFICE.label,
    value: ROLES.FRONT_OFFICE.value,
  },
  {
    label: ROLES.BUILDING_MANAGER.label,
    value: ROLES.BUILDING_MANAGER.value,
  },
];

const emptyAccountFields: AccountFields = {
  name: "",
  surname: "",
  email: "",
  phone: "",
  language: undefined,
  role: undefined,
  assets: [],
};

// TODO: use if want prefix
// const getCountryPrefix = (countryLabel: string) => {
//   return `+${countryLabel.split("+")[1]}`;
// };

const getFieldMaxLength = (field: keyof AccountFields) => {
  if (field === "email") {
    return 40;
  }
  return 50;
};

const AccountManagerDrawer: React.FC<AccountManagerDrawerProps> = observer(
  ({ isOpen, setIsOpen, accountFields, onClose, onSuccess, user }) => {
    const { i18n, t } = useTranslation();
    const store = useStore();
    const initialPhoneCountry = getCountryAndPrefix(
      (i18n.language.split("-")[1] || "IT") as PhoneCountry,
      i18n.language.split("-")[0] as Locales,
    );
    const isEditMode = !!user;
    const disableIfNotAdmin =
      isEditMode && store.loggedUser?.role !== ROLES.ADMIN.value;
    const [country, setCountry] = useState<OptionSelect>(initialPhoneCountry);
    const languageOptions: OptionSelect[] =
      store.languages?.map((lang) => ({
        label: t(`iTd.language.${lang}`),
        value: lang,
      })) || [];

    const onCloseDrawer = () => {
      setIsOpen(false);
      setCountry(initialPhoneCountry);
      if (onClose) {
        onClose();
      }
    };
    const {
      register,
      onCreateUser,
      onUpdateUser,
      errors: formErrors,
      isLoading,
      control,
      setValue,
      setError,
      getValues,
      clearErrors,
    } = useBackOfficeUserForm({
      closeDrawer: onCloseDrawer,
      defaultValues: emptyAccountFields,
      onSuccess,
      userId: user?.id,
    });
    const { getFieldError } = useFieldValidator();

    // Handlers
    const handleChangePhone = (e: React.ChangeEvent<HTMLInputElement>) => {
      const { value } = e.target;
      setValue("phone", value);
      const isValid = isValidNumber(country.value as PhoneCountry, value);
      if (value && !isValid) {
        setError("phone", { type: "pattern" });
      } else {
        clearErrors("phone");
      }
    };
    const handleChangeCountry = (newValue: OptionSelect | OptionSelect[]) => {
      if (getValues().phone) {
        const isValid = isValidNumber(
          (newValue as OptionSelect).value as PhoneCountry,
          getValues().phone || "",
        );
        if (isValid && formErrors?.phone) {
          clearErrors("phone");
        }
      }
      setCountry(newValue as OptionSelect);
    };
    const checkIfChanged = (): boolean => {
      return JSON.stringify(getValues()) !== JSON.stringify(accountFields);
    };
    const handleSubmit = () => {
      if (isLoading) return;
      if (isEditMode) {
        if (checkIfChanged()) onUpdateUser();
      } else {
        onCreateUser();
      }
    };

    useEffect(() => {
      if (isOpen) {
        if (accountFields) {
          Object.keys(accountFields).forEach((key) => {
            setValue(
              key as keyof AccountFields,
              accountFields[key as keyof AccountFields],
            );
          });
        }
        if (!accountFields?.assets) {
          setValue("assets", store.projectsFilterOptions ?? []);
        }
      } else {
        clearErrors();
        const fieldValues = getValues();
        Object.keys(fieldValues).forEach((field) => {
          if (fieldValues[field as keyof AccountFields]) {
            setValue(
              field as keyof AccountFields,
              emptyAccountFields[field as keyof AccountFields],
            );
          }
        });
      }
    }, [isOpen]);

    const getCommonProps = (field: keyof AccountFields) => {
      return {
        id: field,
        label: t(`iTd.accountManager.field.${field}.title`),
        placeholder: t(`iTd.accountManager.field.${field}.placeholder`),
        status: formErrors[field]
          ? "error"
          : (undefined as "error" | undefined),
        helpText: getFieldError(field, formErrors),
        disabled: field !== "assets" && disableIfNotAdmin,
      };
    };
    const getCommonSelectProps = (
      field: keyof AccountFields,
      onBlur: () => void,
    ) => {
      return {
        isRequired: true,
        error: !!formErrors[field],
        menuPlacement: "auto" as "top" | "bottom" | "auto",
        onMenuClose: onBlur,
      };
    };

    return (
      <Drawer
        anchor="right"
        open={isOpen}
        onClose={onCloseDrawer}
        className="account-manager-drawer"
      >
        <Drawer.Header
          label={
            isEditMode
              ? t("iTd.accountManager.editAccount")
              : t("iTd.accountManager.addAccount")
          }
          onClose={onCloseDrawer}
        />
        <Drawer.Content>
          <form onSubmit={handleSubmit}>
            <Input
              {...getCommonProps("name")}
              type="text"
              required
              {...register("name", {
                required: true,
                pattern: NAME_REGEX,
                maxLength: getFieldMaxLength("name"),
              })}
            />
            <Input
              {...getCommonProps("surname")}
              type="text"
              required
              {...register("surname", {
                required: true,
                pattern: NAME_REGEX,
                maxLength: getFieldMaxLength("surname"),
              })}
            />
            <Input
              {...getCommonProps("email")}
              type="text"
              required
              {...register("email", {
                required: true,
                pattern: EMAIL_REGEX,
                maxLength: getFieldMaxLength("email"),
              })}
            />
            {country && (
              <Controller
                name="phone"
                control={control}
                render={({ field: { value } }) => (
                  <PhoneInput
                    {...getCommonProps("phone")}
                    value={value}
                    onChange={handleChangePhone}
                    currentLanguage={i18n.language.split("-")[0] as Locales}
                    selectedCountry={country}
                    onChangeCountry={handleChangeCountry}
                    extraLabel={`(${t("iTd.field.optional")})`}
                    direction="horizontal"
                  />
                )}
              />
            )}
            <Controller
              name="language"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...getCommonProps("language")}
                  {...getCommonSelectProps("language", field.onBlur)}
                  {...field}
                  options={languageOptions}
                  value={field.value as OptionSelect}
                  closeMenuOnSelect
                />
              )}
            />
            <Controller
              name="role"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...getCommonProps("role")}
                  {...getCommonSelectProps("role", field.onBlur)}
                  {...field}
                  options={roleOptions}
                  value={field.value as OptionSelect}
                  closeMenuOnSelect
                />
              )}
            />
            <Controller
              name="assets"
              control={control}
              rules={{ required: true }}
              render={({ field }) => (
                <Select
                  {...getCommonProps("assets")}
                  {...getCommonSelectProps("assets", field.onBlur)}
                  {...field}
                  options={store.projectsFilterOptions ?? []}
                  value={field.value as OptionSelect[]}
                  isMulti
                  noOptionsMessage={t("iTd.reports.initiative.noOptions")}
                  searchPlaceholder={t("iTd.reports.initiative.search")}
                  selectAllLabel={t("iTd.reports.initiative.selectAll")}
                  deselectAllLabel={t("iTd.reports.initiative.deselectAll")}
                  showSelectedItemRemoveIcon={false}
                />
              )}
            />
          </form>
        </Drawer.Content>
        <Drawer.Footer>
          {user?.createdOn && (
            <span>
              {t("iTd.accountManager.createdOn", {
                createdOn: formatDate(new Date(user.createdOn), i18n.language),
              })}
            </span>
          )}
          <div className="footer-actions">
            <Button onClick={() => setIsOpen(false)} color="secondary">
              {t("iTd.accountManager.cancel")}
            </Button>
            <Button onClick={handleSubmit}>
              {isLoading && <Spinner type="dotted-circle" size="small" />}
              {isEditMode
                ? t("iTd.accountManager.saveChanges")
                : t("iTd.accountManager.add")}
            </Button>
          </div>
        </Drawer.Footer>
      </Drawer>
    );
  },
);

export default AccountManagerDrawer;
